import { Typography } from "src/components";
import { useConversations } from "../../context/useConversations";
import { ConversationStatus } from "../../types";

const OpenConversationsContent = () => {
  const { setStatus } = useConversations();

  return (
    <Typography style={{ marginTop: "24px" }}>
      Looking for a message? Check the{" "}
      <span onClick={() => setStatus(ConversationStatus.ARCHIVED)}>
        Archived threads
      </span>
    </Typography>
  );
};

export default OpenConversationsContent;

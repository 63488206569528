import { Conversation, SupportMessage } from "src/api/types";
import styles from "./styles.module.scss";
import Message from "../Message";
import { Header } from "..";
import { useRef } from "react";
import MessageInput from "../MessageInput";
import useScrollToBottom from "./hooks/useScrollToBottom";

interface ChatProps {
  messages: SupportMessage[];
  conversation: Conversation;
}

const Chat = ({ messages, conversation }: ChatProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useScrollToBottom(ref, messages);

  return (
    <div className={styles.support_chat}>
      <Header subject={conversation.subject} />
      <div className={styles.chat_messages} ref={ref}>
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </div>
      <MessageInput />
    </div>
  );
};

export default Chat;

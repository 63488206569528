import { SVGProps } from "react";
export const CheckMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={48} height={48} fill="#D1FAE5" rx={24} />
    <path
      stroke="#059669"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m17 25 4 4 10-10"
    />
  </svg>
);

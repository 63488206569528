import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useAppDispatch } from "src/store/hooks";
import { storeAuthToken } from "src/store/tokenReducer";

const useFetchAuthToken = () => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          dispatch(storeAuthToken(token));
        } catch (error) {
          logout();
        }
      }
    })();
    // eslint-disable-next-line
  }, [isAuthenticated]);
};

export default useFetchAuthToken;

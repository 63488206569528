import { managementApi } from "../instances";
import { extractFiles } from "src/utils/extractFiles";
import { SendMessageProps } from "../types";

export const addConversationMessage = ({
  expertId,
  conversation,
  body,
}: SendMessageProps) => {
  if (!conversation) return;

  const messageText = body.get("body")?.toString() || "";
  const date = new Date().getTime();

  return managementApi.util.updateQueryData(
    "getConversationMessages",
    {
      expertId,
      conversation,
    },
    (draft) => {
      draft.push({
        body: messageText,
        created_at: date,
        id: date.toString(),
        text: messageText,
        is_inbound: true,
        attachments: extractFiles(body, "attachments"),
      });
    }
  );
};

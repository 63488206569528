import Modal from "react-modal";

export const modalStyle = (style: Modal.Props["style"]) => ({
  content: {
    borderRadius: "16px",
    padding: "24px",
    margin: "auto",
    height: "fit-content",
    ...style?.content,
  },

  overlay: {
    backgroundColor: "#4C536266",
    ...style?.overlay,
  },
});

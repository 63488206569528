import { SupportMessage } from "src/api/types";
import { Typography } from "src/components";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import MessageAttachments from "../MessageAttachments";

const Message = ({ message }: { message: SupportMessage }) => {
  return (
    <div
      className={`${
        styles[`message-${message.is_inbound ? "inbound" : "outbound"}`]
      } ${styles.message_wrapper}`}
    >
      <div className={styles["message-content_wrapper"]}>
        <MessageAttachments attachments={message.attachments} />
        <div
          dangerouslySetInnerHTML={{ __html: message.body }}
          className={styles.message_body}
        />
      </div>
      <Typography variant="body3">
        {dayjs(message.created_at).format("hh:mm A")}
      </Typography>
    </div>
  );
};

export default Message;

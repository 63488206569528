import { ReactNode } from "react";
import Modal from "react-modal";
import { modalStyle } from "./style";

interface ModalProps extends Modal.Props {
  children: ReactNode;
}

Modal.setAppElement("#root");

const CustomModal = ({ children, style, ...rest }: ModalProps) => {
  return (
    <Modal style={modalStyle(style)} {...rest}>
      {children}
    </Modal>
  );
};

export default CustomModal;

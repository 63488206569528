import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQuery from "../utils/fetchBaseQuery";
import { cmsUrls } from "../urls";
import { CMSResponseType, NotificationContent, SupportTopic } from "../types";

const transformCmsResponse = <T>(res: CMSResponseType<T, true>) => {
  return res.data.map(({ attributes, id }) => ({ ...attributes, id }));
};

export const cmsApi = createApi({
  reducerPath: "cmsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.CMS_URL,
  }),
  endpoints: (builder) => ({
    getSupportTopics: builder.query<SupportTopic[], void>({
      query: cmsUrls.supportTopics,
      transformResponse: (res: CMSResponseType<SupportTopic, true>) =>
        transformCmsResponse(res),
    }),

    getNotificationContent: builder.query<NotificationContent, string>({
      query: cmsUrls.notificationContent,
      transformResponse: (res: CMSResponseType<NotificationContent, false>) =>
        res.data.attributes,
    }),
  }),
});

export const { useGetSupportTopicsQuery, useGetNotificationContentQuery } =
  cmsApi;

import { SVGProps } from "react";

export const ScrollMark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={39}
    fill="none"
    {...props}
  >
    <rect width={39} height={39} x={0.5} y={0.5} fill="#fff" rx={19.5} />
    <rect width={39} height={39} x={0.5} y={0.5} stroke="#F7ECFF" rx={19.5} />
    <path
      stroke="#292D32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M26.07 22.43 20 28.5l-6.07-6.07M20 11.5v16.83"
    />
  </svg>
);

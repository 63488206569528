import { Emoji } from "src/assets/icons";
import Picker from "emoji-picker-react";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";

interface EmojiPickerProps {
  onSelect: (emoji: string) => void;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EmojiPicker = ({ isOpen, setIsOpen, onSelect }: EmojiPickerProps) => {
  const [firstOpened, setFirstOpened] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!firstOpened && isOpen) setFirstOpened(true);
  }, [firstOpened, isOpen]);

  return (
    <div className={styles.container}>
      <div onClick={toggleOpen} className={styles.icon_wrapper}>
        <Emoji />
      </div>
      <div
        className={styles.picker_wrapper}
        style={{ width: isOpen ? 350 : 0, height: isOpen ? 450 : 0 }}
      >
        {firstOpened && (
          <Picker onEmojiClick={({ emoji }) => onSelect(emoji)} />
        )}
      </div>
    </div>
  );
};

export default EmojiPicker;

import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";

export const useLDIdentify = () => {
  const ldClient = useLDClient();

  const { data: expert } = useGetExpertQueryResolver();

  useEffect(() => {
    if (!ldClient || !expert) return;

    ldClient.identify({
      kind: "user",
      email: expert?.email,
      key: expert?.uuid,
    });
  }, [expert, ldClient]);
};

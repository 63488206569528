export const extractFiles = (formData: FormData, fileKey: string) => {
  const fileUrls = [];

  for (const [key, value] of formData.entries()) {
    if (key.startsWith(fileKey)) {
      if (value instanceof File) {
        const fileUrl = URL.createObjectURL(value);
        fileUrls.push(fileUrl);
      }
    }
  }

  return fileUrls;
};

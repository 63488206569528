import { useAuth0 } from "@auth0/auth0-react";
import styles from "./styles.module.scss";
import { RitualLogo } from "src/assets/icons";

const Header = () => {
  const { user } = useAuth0();

  return (
    <div className={styles.header}>
      <RitualLogo />
      <img src={user?.picture} alt="expert_avatar" />
    </div>
  );
};

export default Header;

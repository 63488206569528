import {
  useGetConversationMessagesQuery,
  useGetSupportConversationsQuery,
} from "../instances/management";
import { useExpertData } from "./expertResolvers";

export const useGetConversationsResolver = (queryParams: string) => {
  const { expertId, skip } = useExpertData();

  return useGetSupportConversationsQuery({ expertId, queryParams }, { skip });
};

export const useGetConversationMessagesResolver = (conversation: string) => {
  const { expertId, skip } = useExpertData();

  return useGetConversationMessagesQuery(
    { expertId, conversation },
    { skip: skip || !conversation }
  );
};

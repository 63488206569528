import { SVGProps } from "react";
export const MessagesOpen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      fill="#AC93D7"
      d="M30.776 14H15.843c-.607 0-1.19.023-1.75.093-6.277.537-9.427 4.247-9.427 11.084v9.333c0 9.333 3.733 11.177 11.177 11.177h.933c.513 0 1.19.35 1.493.746l2.8 3.734c1.237 1.656 3.244 1.656 4.48 0l2.8-3.734c.35-.466.91-.746 1.494-.746h.933c6.837 0 10.547-3.127 11.083-9.427.07-.56.094-1.143.094-1.75v-9.333C41.953 17.733 38.219 14 30.776 14Zm-15.61 18.667a2.325 2.325 0 0 1-2.333-2.334A2.34 2.34 0 0 1 15.166 28a2.34 2.34 0 0 1 2.333 2.333 2.34 2.34 0 0 1-2.333 2.334Zm8.143 0a2.325 2.325 0 0 1-2.333-2.334A2.34 2.34 0 0 1 23.309 28a2.34 2.34 0 0 1 2.334 2.333 2.325 2.325 0 0 1-2.334 2.334Zm8.167 0a2.325 2.325 0 0 1-2.333-2.334A2.34 2.34 0 0 1 31.476 28a2.34 2.34 0 0 1 2.333 2.333 2.34 2.34 0 0 1-2.333 2.334Z"
    />
    <path
      fill="#AC93D7"
      d="M51.287 15.844v9.333c0 4.667-1.447 7.84-4.34 9.59-.7.42-1.517-.14-1.517-.957l.023-8.633c0-9.333-5.343-14.677-14.676-14.677l-14.21.024c-.817 0-1.377-.817-.957-1.517 1.75-2.893 4.923-4.34 9.567-4.34H40.11c7.443 0 11.177 3.733 11.177 11.177Z"
    />
  </svg>
);

import { Typography } from "src/components";
import styles from "./styles.module.scss";
import { links } from "./utils/links";
import cn from "classnames";
import { useLocation } from "react-router-dom";
import useRedirect from "src/hooks/useRedirect";

const Sidebar = () => {
  const { pathname } = useLocation();
  const redirect = useRedirect();

  return (
    <div className={styles.sidebar}>
      {links.map((link) => (
        <div
          onClick={() => redirect({ type: link.type, url: link.route })}
          key={link.label}
          className={cn(styles.link, {
            [styles.link_active]: link.route === pathname,
          })}
        >
          {link.logo}
          <Typography variant="body-sm">{link.label}</Typography>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;

import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Unauthorized = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout]);

  return <></>;
};

export default Unauthorized;

import { PropsWithChildren, SVGProps } from "react";
import { Typography } from "src/components";
import styles from "./styles.module.scss";

interface EmptyStateProps extends PropsWithChildren {
  Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  title: string;
  subtitle: string;
}

const EmptyState = ({ Icon, title, subtitle, children }: EmptyStateProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Icon />
        <Typography variant="h3">{title}</Typography>
        <Typography variant="body-large">{subtitle}</Typography>
        {children}
      </div>
    </div>
  );
};

export default EmptyState;

import { useConversations } from "src/pages/Support/context/useConversations";
import ConversationCard from "./ui/ConversationCard";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "./ui/Loader";
import ConversationContainer from "./ui/ConversationContainer";

const SupportConversations = () => {
  const { conversations, fetchNext, hasMore } = useConversations();

  return (
    <ConversationContainer>
      <InfiniteScroll
        dataLength={conversations.length}
        next={fetchNext}
        hasMore={hasMore}
        loader={<Loader />}
        scrollableTarget="scrollableDiv"
      >
        {conversations.map((conversation) => (
          <ConversationCard key={conversation.id} conversation={conversation} />
        ))}
      </InfiniteScroll>
    </ConversationContainer>
  );
};

export default SupportConversations;

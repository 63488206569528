import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App.tsx";
import "src/styles/index.scss";
import { AuthProvider } from "src/providers/index.tsx";
import { ErrorLayout } from "./components/index.tsx";
import { Provider } from "react-redux";
import { store } from "./store/index.ts";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer } from "react-toastify";

import * as Sentry from "@sentry/react";

if (!window.location.origin.includes("localhost")) {
  Sentry.init({
    dsn: "https://93a12014b3bc5d83f86d28732c29e351@o4507695388098560.ingest.us.sentry.io/4507741104963584",
    integrations: [],
    environment: process.env.APP_ENV,
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={<ErrorLayout />}>
      <AuthProvider>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </AuthProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

import { SVGProps } from "react";
export const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#1F2222"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="m14.94 6.713-4.89 4.89a1.49 1.49 0 0 1-2.1 0l-4.89-4.89"
    />
  </svg>
);

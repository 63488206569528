import { SVGProps } from "react";

export const TickCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#7C7F87"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 18.333c4.584 0 8.334-3.75 8.334-8.333S14.584 1.667 10 1.667c-4.583 0-8.333 3.75-8.333 8.333s3.75 8.333 8.333 8.333Z"
    />
    <path
      stroke="#7C7F87"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.458 10 2.358 2.358 4.725-4.716"
    />
  </svg>
);

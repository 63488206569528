import { Attachments } from "src/assets/icons";
import {
  Button,
  Input,
  ModalAlertMessage,
  Select,
  Typography,
} from "src/components";
import styles from "./styles.module.scss";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useSendSupportMessageMutation } from "src/api/instances/management";
import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";
import InputAttachments from "src/entities/SupportChat/ui/InputAttachments";
import { useState } from "react";
import { useGetSupportTopicsQuery } from "src/api/instances/cms";
import { extractError, generatePayload } from "./utils";

const ModalContent = ({ closeModal }: { closeModal: () => void }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { data: options } = useGetSupportTopicsQuery();
  const { data: expert } = useGetExpertQueryResolver();

  const [files, setFiles] = useState<File[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const [sendMessage, { isError: requestError }] =
    useSendSupportMessageMutation();

  const onSubmit = (data: FieldValues) => {
    const payload = generatePayload(data);

    sendMessage({ expertId: expert!.uuid, body: payload })
      .unwrap()
      .then(() => setSubmitted(true));
  };

  const validationError = extractError(errors);

  if (submitted) {
    return (
      <ModalAlertMessage
        title="Message sent!"
        subtitle="Thank you for reaching out! Your message has been received. Once reviewed, it will appear in your Open threads."
        onClick={closeModal}
      />
    );
  }

  return (
    <div>
      <div className={styles.heading}>
        <Typography variant="h2">How can we help?</Typography>
        <Typography>
          We'll review and assist with your issue promptly.
        </Typography>
      </div>
      <div className={styles.fields}>
        <Controller
          name="subject"
          rules={{ required: "Select topic" }}
          control={control}
          render={({ field }) => (
            <Select options={options || []} {...field} placeholder="Topic" />
          )}
        />
        <Typography>How can we help?</Typography>
        <Input
          placeholder="How can we help?"
          tag="textarea"
          label="text"
          registerProps={{ required: "Fill body text" }}
          register={register}
        />
        <Input
          placeholder="Add link (Optional)"
          label="link"
          register={register}
        />
      </div>
      <div className={styles.upload_file}>
        <Typography className={styles["upload_file-title"]}>
          Upload file (Optional)
        </Typography>
        <Typography>Add an image or a file to your message.</Typography>
        <Button variant="secondary">
          <label htmlFor="file-upload">
            <Attachments />
            Upload file
          </label>
        </Button>
        <input
          id="file-upload"
          type="file"
          multiple
          {...register("attachments", {
            onChange: (e) => setFiles([...e.target.files]),
          })}
        />
        <InputAttachments files={files} />
      </div>
      {validationError && (
        <Typography className={styles.error}>{validationError}</Typography>
      )}
      {requestError && (
        <Typography className={styles.error}>
          Something went wrong. Please try again
        </Typography>
      )}
      <div className={styles.action_buttons}>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Send message</Button>
      </div>
    </div>
  );
};

export default ModalContent;

import { HTMLAttributes, ReactNode } from "react";
import styles from "./styles.module.scss";

interface ButtonProps extends HTMLAttributes<HTMLElement> {
  variant?: "primary" | "secondary";
  children: ReactNode;
}
const Button = ({ variant = "primary", children, ...rest }: ButtonProps) => {
  return (
    <button
      {...rest}
      className={[
        styles.button_default,
        styles[`button-${variant}`],
        rest.className || "",
      ].join(" ")}
    >
      {children}
    </button>
  );
};

export default Button;

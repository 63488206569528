import { Button, Typography } from "src/components";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Routes } from "src/router/routes";

const SupportCard = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(Routes.SUPPORT);
  };

  return (
    <div className={styles.support_card}>
      <Typography variant="h3">We are here to help</Typography>
      <Typography>Message us an we'll promptly assist you.</Typography>
      <div className={styles.button_row}>
        <Button onClick={onClick}>Go to Support</Button>
      </div>
    </div>
  );
};

export default SupportCard;

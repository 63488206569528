import { Bell, BellWithClock } from "src/assets/icons";
import { NotificationStatus } from "./types";

export const emptyStateContent = {
  [NotificationStatus.ACTIVE]: {
    Icon: Bell,
    title: "You have no new updates",
    text: "Looks like you’ve confirmed all of your updates.",
  },
  [NotificationStatus.ARCHIVE]: {
    Icon: BellWithClock,
    title: "No updates in the Archive",
    text: "Nothing has been archived yet. Once an update is marked as confirmed, it will appear here.",
  },
};

import { Typography } from "src/components";
import dayjs from "dayjs";
import styles from "./styles.module.scss";
import { Conversation } from "src/api/types";
import { useConversations } from "src/pages/Support/context/useConversations";
import cn from "classnames";

interface ConversationCardProps {
  conversation: Conversation;
}

const ConversationCard = ({ conversation }: ConversationCardProps) => {
  const { subject, last_message: lastMessage } = conversation;
  const time = lastMessage?.created_at;

  const { setActiveConversation, activeConversation } = useConversations();

  return (
    <div
      className={cn(styles.card, {
        [styles.selected]: activeConversation?.id === conversation.id,
      })}
      onClick={() => setActiveConversation(conversation)}
    >
      <Typography className={styles.subject}>
        {subject || "No subject"}
      </Typography>
      <div className={styles.last_message}>
        <Typography variant="body2" className={styles["last_message-text"]}>
          {lastMessage?.text}
        </Typography>
        {time && (
          <Typography variant="body2">
            {dayjs(time * 1000).format("hh:mm a")}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ConversationCard;

import styles from "./styles.module.scss";

const MessageAttachments = ({ attachments }: { attachments: string[] }) => {
  return (
    <div className={styles.attachments}>
      {attachments.map((attachment, i) => (
        <img
          key={i}
          src={
            attachment.includes("blob:")
              ? attachment
              : `data:image/jpeg;base64,${attachment}`
          }
        />
      ))}
    </div>
  );
};

export default MessageAttachments;

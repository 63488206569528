import { CSSObjectWithLabel } from "react-select";

const textStyle = {
  fontFamily: "Avenir Next",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "24px",
};

export const customStyles = {
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    borderRadius: "12px",
    border: "1px solid #D1D5DB",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
    borderColor: "#D1D5DB !important",
    padding: "5px 8px",
  }),

  indicatorSeparator: (base: CSSObjectWithLabel) => ({
    ...base,
    display: "none",
  }),

  singleValue: (base: CSSObjectWithLabel) => ({
    ...base,
    ...textStyle,
  }),

  input: (base: CSSObjectWithLabel) => ({
    ...base,
    ...textStyle,
  }),

  option: (
    base: CSSObjectWithLabel,
    { isSelected, isFocused }: { isSelected: boolean; isFocused: boolean }
  ) => ({
    ...base,
    ...textStyle,
    color: "black",
    backgroundColor:
      isSelected || isFocused ? "#e9dbf6 !important" : "white !important",
  }),

  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    ...textStyle,
    color: "#575961",
  }),
};

import { ArrowDown } from "src/assets/icons";
import Typography from "../Typography";
import styles from "./styles.module.scss";
import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "src/hooks";

interface DropdownProps {
  options: { label: string; value: unknown }[];
  onSelect: (value: unknown) => void;
  value: string;
}

const Dropdown = ({ options, onSelect, value }: DropdownProps) => {
  const [selected, setSelected] = useState(options[0]);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    const updatedValue = options.find((option) => option.value === value);
    updatedValue && setSelected(updatedValue);
  }, [options, value]);

  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideClick(ref, () => setOpened(false));

  const toggleOpen = () => {
    setOpened((prev) => !prev);
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.dropdown} onClick={toggleOpen}>
        <Typography variant="body2">{selected.label}</Typography>
        <ArrowDown />
      </div>
      {opened && (
        <div className={styles.menu}>
          {options.map((option) => (
            <div
              className={styles.menu_item}
              onClick={() => {
                onSelect(option.value);
                setSelected(option);
                setOpened(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import { Typography } from "src/components";
import styles from "./styles.module.scss";

const InputAttachments = ({ files }: { files: File[] }) => {
  return (
    <div className={styles.wrapper}>
      {files.map((file, index) => (
        <div key={index} className={styles.file}>
          <Typography variant="body2">{file.name}</Typography>
        </div>
      ))}
    </div>
  );
};

export default InputAttachments;

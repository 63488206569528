import { useNotifications } from "@magicbell/magicbell-react";
import { Typography } from "ritual-ui";
import styles from "./styles.module.scss";

const Header = () => {
  const store = useNotifications();

  const unreadCount = store?.unreadCount || 0;

  return (
    <>
      <Typography variant="headline4" weight={500}>
        Updates
      </Typography>
      <Typography className={styles.subtitle} weight={500}>
        {unreadCount ? (
          <>
            You have <span>{unreadCount} unread </span>update
            {unreadCount > 1 ? "s" : ""}
          </>
        ) : (
          "You have no new updates"
        )}
      </Typography>
    </>
  );
};

export default Header;

import { useState, useEffect, RefObject } from "react";

const OFFSET = 30;

export const useScrollMark = (containerRef: RefObject<HTMLDivElement>) => {
  const [showScrollMark, setShowScrollMark] = useState(false);

  useEffect(() => {
    const checkForScrolling = () => {
      const scrollableElement = containerRef.current;
      if (!scrollableElement) return;

      const { scrollHeight, scrollTop, clientHeight } = scrollableElement;

      const isScrolledToBottom =
        scrollHeight - scrollTop - OFFSET <= clientHeight;

      setShowScrollMark(!isScrolledToBottom);
    };

    const scrollableElement = containerRef.current;
    if (scrollableElement) {
      setShowScrollMark(
        scrollableElement.scrollHeight > scrollableElement.clientHeight
      );

      scrollableElement.addEventListener("scroll", checkForScrolling);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", checkForScrolling);
      }
    };
  }, [containerRef]);

  return showScrollMark;
};

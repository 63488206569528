import { forwardRef } from "react";
import Select, { Props } from "react-select";
import { customStyles } from "./styles";

interface SelectProps extends Props {
  options: { value: string | number; label: string }[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomSelect = forwardRef(({ options, ...rest }: SelectProps, _ref) => {
  return (
    <div>
      <Select options={options} styles={customStyles} {...rest} />
    </div>
  );
});

export default CustomSelect;

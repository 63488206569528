import { useEffect, useState } from "react";
import { Button, Modal } from "src/components";
import { ModalContent } from "./components";
import { useLocation } from "react-router-dom";

const SupportModal = () => {
  const [isOpen, setOpen] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (state?.opened) setOpen(true);
  }, [state]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>New message</Button>
      <Modal
        isOpen={isOpen}
        style={{ content: { maxWidth: "520px" } }}
        onRequestClose={closeModal}
      >
        <ModalContent closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default SupportModal;

export const BellWithClock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={56} height={56} fill="none">
    <path
      fill="#AC93D7"
      d="m45.126 33.81-2.333-3.874c-.49-.863-.934-2.496-.934-3.453V20.58c0-5.483-3.22-10.22-7.863-12.437-1.213-2.147-3.453-3.477-6.02-3.477a6.909 6.909 0 0 0-6.043 3.547c-4.55 2.264-7.7 6.954-7.7 12.367v5.903c0 .957-.444 2.59-.934 3.43l-2.356 3.897c-.934 1.563-1.143 3.29-.56 4.877.56 1.563 1.89 2.776 3.617 3.36 4.526 1.54 9.286 2.286 14.046 2.286s9.52-.746 14.047-2.263c1.633-.537 2.893-1.773 3.5-3.383a5.545 5.545 0 0 0-.467-4.877Z"
    />
    <path
      fill="#AC93D7"
      d="M44 12a6.01 6.01 0 0 0-6 6c0 3.306 2.694 6 6 6s6-2.694 6-6-2.694-6-6-6Zm2.61 8.142a.448.448 0 0 1-.618.156l-1.86-1.11c-.462-.276-.804-.882-.804-1.416v-2.46c0-.246.204-.45.45-.45.246 0 .45.204.45.45v2.46c0 .216.18.534.366.642l1.86 1.11a.444.444 0 0 1 .156.618ZM34.603 46.69c-.98 2.707-3.57 4.643-6.603 4.643-1.843 0-3.663-.746-4.947-2.076-.746-.7-1.306-1.634-1.633-2.59.303.046.607.07.933.116.537.07 1.097.14 1.657.187 1.33.117 2.683.187 4.037.187 1.33 0 2.66-.07 3.966-.187.49-.047.98-.07 1.447-.14.373-.047.747-.093 1.143-.14Z"
    />
    <circle cx={44} cy={18} r={7} stroke="#F7F7FA" strokeWidth={2} />
  </svg>
);

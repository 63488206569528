import { SVGProps } from "react";
export const Emoji = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#7C7F87"
      d="M13 8.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM7 8.75a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
    <circle cx={10} cy={10} r={8.25} stroke="#7C7F87" strokeWidth={1.5} />
    <path
      stroke="#7C7F87"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M6 12c1.03 1.237 2.443 2 4 2s2.97-.763 4-2"
    />
  </svg>
);

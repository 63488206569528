import { Button } from "src/components";
import FilePicker from "../../components/FilePicker";
import EmojiPicker from "../../components/EmojiPicker";
import { useState } from "react";
import styles from "./styles.module.scss";
import { useSendSupportMessageMutation } from "src/api/instances/management";
import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";
import { useConversations } from "src/pages/Support/context/useConversations";
import InputAttachments from "../InputAttachments";
import { toast } from "react-toastify";

const MessageInput = () => {
  const [text, setText] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);

  const { data: expert } = useGetExpertQueryResolver();
  const { activeConversation } = useConversations();

  const [sendMessage] = useSendSupportMessageMutation();

  const handleEmojiSelect = (emoji: string) => {
    setText((prev) => prev + emoji);
  };

  const clearInput = () => {
    setText("");
    setFiles([]);
  };

  const onClick = () => {
    const data = new FormData();
    files.forEach((file, i) => data.append(`attachments[${i}]`, file));
    data.append("handle", activeConversation?.handle || "");
    data.append("body", text);

    clearInput();

    sendMessage({
      expertId: expert!.uuid,
      body: data,
      conversation: activeConversation?.id || "",
    })
      .unwrap()
      .catch(() => toast.error("Something went wrong. Please try again"));
  };

  return (
    <div className={styles.input_wrapper}>
      <InputAttachments files={files} />
      <textarea value={text} onChange={(e) => setText(e.currentTarget.value)} />
      <div className={styles.actions}>
        <div className={styles.action_buttons}>
          <FilePicker setFiles={setFiles} />
          <EmojiPicker
            isOpen={isEmojiOpen}
            setIsOpen={setIsEmojiOpen}
            onSelect={handleEmojiSelect}
          />
        </div>
        <Button onClick={onClick}>Send</Button>
      </div>
    </div>
  );
};

export default MessageInput;

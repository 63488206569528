import { UpdateFeed } from "src/entities";
import { Header, SupportCard } from "./components";
import styles from "./styles.module.scss";
import { useFlags } from "launchdarkly-react-client-sdk";

const Home = () => {
  const { expertUpdateFeed } = useFlags();

  return (
    <div className={styles.content}>
      <Header />
      <div className={styles.top_row}>
        {expertUpdateFeed && <UpdateFeed />}
        <SupportCard />
      </div>
    </div>
  );
};

export default Home;

import { PropsWithChildren } from "react";
import { ArrowLeft } from "src/assets/icons";
import { Button } from "src/components";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Routes } from "src/router/routes";

const PageWithBackButton = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="secondary"
        className={styles.button}
        onClick={() => navigate(Routes.HOME)}
      >
        <ArrowLeft />
        Back to Home
      </Button>
      {children}
    </>
  );
};

export default PageWithBackButton;

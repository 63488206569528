import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useGetConversationsResolver } from "src/api/resolvers/supportResolvers";
import { Conversation } from "src/api/types";
import { ConversationStatus } from "../types";

interface ContextValue {
  fetchNext: () => void;
  conversations: Conversation[];
  hasMore: boolean;
  status: ConversationStatus;
  setStatus: React.Dispatch<React.SetStateAction<ConversationStatus>>;
  activeConversation: Conversation | null;
  setActiveConversation: React.Dispatch<
    React.SetStateAction<Conversation | null>
  >;
}

export const ConversationContext = createContext<ContextValue>(
  {} as ContextValue
);

const ConversationsProvider = ({ children }: PropsWithChildren) => {
  const [status, setStatus] = useState<ConversationStatus>(
    ConversationStatus.OPEN
  );
  const [nextPage, setNextPage] = useState("");

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const { data: response, isFetching } = useGetConversationsResolver(
    `status=${status}${nextPage ? `&page_token=${nextPage}` : ""}`
  );

  const [activeConversation, setActiveConversation] =
    useState<Conversation | null>(null);

  useEffect(() => {
    if (!activeConversation && conversations.length) {
      setActiveConversation(conversations[0]);
    }
  }, [conversations, activeConversation]);

  useEffect(() => {
    setConversations([]);
    setActiveConversation(null);
    setNextPage("");
  }, [status]);

  useEffect(() => {
    if (response) {
      setConversations((prev) => {
        const arr = [...prev];
        response.results.forEach((obj) => {
          if (!arr.some((item) => item.id === obj.id)) arr.push(obj);
        });

        return arr;
      });
    }
  }, [response]);

  const fetchNext = () => {
    if (response) {
      setNextPage(response.next_page);
    }
  };

  const value = {
    conversations,
    fetchNext,
    status,
    setStatus,
    hasMore: isFetching || !!response?.next_page,
    activeConversation,
    setActiveConversation,
  };

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  );
};

export default ConversationsProvider;

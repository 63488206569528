import { FieldValues, RegisterOptions, UseFormRegister } from "react-hook-form";
import styles from "./styles.module.scss";

interface InputProps {
  tag?: "input" | "textarea";
  placeholder?: string;
  className?: string;
  label: string;
  registerProps?: RegisterOptions<FieldValues, string>;
  register: UseFormRegister<FieldValues>;
}

const Input = ({
  tag = "input",
  className,
  register,
  label,
  registerProps,
  ...rest
}: InputProps) => {
  const Component = tag;

  return (
    <div className={styles.wrapper}>
      <Component
        className={[styles.input, className].join(" ")}
        {...register(label, registerProps)}
        {...rest}
      />
    </div>
  );
};

export default Input;

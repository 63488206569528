import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Loader } from "src/components";

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        audience: process.env.AUTH0_AUDIENCE,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader />;
};

export default Login;

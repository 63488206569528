import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { useFetchAuthToken, useLDIdentify } from "./hooks";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { useAnalyticsIdentify } from "./hooks/useAnalyticsIdentify";

function App() {
  useFetchAuthToken();
  useLDIdentify();
  useAnalyticsIdentify();

  return <RouterProvider router={router} />;
}

const WrappedApp = withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_ID || "",
})(App);

export default WrappedApp;

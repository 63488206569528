import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { fetchBaseQuery as _fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Routes } from "src/router/routes";

const throwRes = (res: Response) => {
  const { statusText: reason, status } = res;

  if (status === 401) window.location.pathname = Routes.UNAUTHORIZED_ROUTE;
  throw new Error(reason);
};

const defaultFetchFn = (input: RequestInfo, init: RequestInit | undefined) => {
  return fetch(input, init).then((res) => (res.ok ? res : throwRes(res)));
};

export default function fetchBaseQuery({
  fetchFn = defaultFetchFn,
  ...args
}: FetchBaseQueryArgs) {
  return _fetchBaseQuery({
    fetchFn,
    ...args,
  });
}

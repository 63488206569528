import { Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";

const AuthProvider = ({ children }: PropsWithChildren) => {
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN || ""}
      clientId={process.env.AUTH0_CLIENT_ID || ""}
      cacheLocation={
        window.location.origin.includes("localhost")
          ? "localstorage"
          : undefined
      }
      useRefreshTokens={process.env.APP_ENV !== "dev"}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.AUTH0_AUDIENCE,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;

import { PageWithBackButton, Typography } from "src/components";
import ConversationsProvider from "./context/ConversationsContext";
import { SupportChat, SupportConversations, SupportModal } from "src/entities";
import StatusSelect from "./components/StatusSelect";
import styles from "./styles.module.scss";

import { useConversations } from "./context/useConversations";
import SupportEmptyState from "./ui/EmptyState";

const Support = () => {
  const { conversations, hasMore } = useConversations();
  const isConversationsEmpty = !conversations.length && !hasMore;

  return (
    <PageWithBackButton>
      <div
        className={[
          styles.wrapper,
          isConversationsEmpty && styles.wrapper_empty,
        ].join(" ")}
      >
        <div className={styles.conversations_container}>
          <Typography variant="h2">Support</Typography>
          <div className={styles.actions_wrapper}>
            <StatusSelect />
            <SupportModal />
          </div>
          <SupportConversations />
        </div>

        <SupportChat />
      </div>
      {isConversationsEmpty && <SupportEmptyState />}
    </PageWithBackButton>
  );
};

const SupportWithProvider = () => {
  return (
    <ConversationsProvider>
      <Support />
    </ConversationsProvider>
  );
};

export default SupportWithProvider;

import Dropdown from "src/components/base/Dropdown";
import { useConversations } from "../../context/useConversations";
import { Typography } from "src/components";
import { ConversationStatus } from "../../types";
import styles from "./styles.module.scss";

const options = [
  { label: "Open threads", value: ConversationStatus.OPEN },
  { label: "Archived threads", value: ConversationStatus.ARCHIVED },
];

const StatusSelect = () => {
  const { setStatus, status } = useConversations();

  return (
    <div className={styles.wrapper}>
      <Typography variant="body2">Show</Typography>
      <Dropdown
        options={options}
        onSelect={(value) => setStatus(value as ConversationStatus)}
        value={status}
      />
    </div>
  );
};

export default StatusSelect;

import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";
import { Typography } from "src/components";

const Header = () => {
  const { data: expert } = useGetExpertQueryResolver();

  return (
    <Typography variant="h1">
      Welcome, <span>{expert?.first_name} 👋</span>
    </Typography>
  );
};

export default Header;

import { SVGProps } from "react";
export const MessagesArchived = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    fill="none"
    {...props}
  >
    <path
      fill="#AC93D7"
      d="M39.666 5.67H16.333c-7 0-11.667 4.667-11.667 11.667v14c0 7 4.667 11.666 11.667 11.666h9.333l10.383 6.907c1.54 1.027 3.617-.07 3.617-1.937v-4.97c7 0 11.667-4.666 11.667-11.666v-14c0-7-4.667-11.667-11.667-11.667Zm-3.5 20.58H19.833c-.957 0-1.75-.793-1.75-1.75s.793-1.75 1.75-1.75h16.333c.957 0 1.75.793 1.75 1.75s-.793 1.75-1.75 1.75Z"
    />
  </svg>
);

import { Typography } from "src/components";
import styles from "./styles.module.scss";

const Header = ({ subject }: { subject: string }) => {
  return (
    <div className={styles.header}>
      <Typography variant="body-large">{subject}</Typography>
    </div>
  );
};

export default Header;

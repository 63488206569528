import { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

const ConversationContainer = ({ children }: PropsWithChildren) => {
  return (
    <div id="scrollableDiv" className={styles.container}>
      {children}
    </div>
  );
};

export default ConversationContainer;

import { useNavigate } from "react-router-dom";
import { LinkType } from "src/api/types";

const useRedirect = () => {
  const navigate = useNavigate();

  const onClick = ({ type, url }: { type: LinkType; url: string }) => {
    switch (type) {
      case LinkType.EXTERNAL:
        window.location.href = url;
        break;
      case LinkType.INTERNAL:
        navigate(url);
        break;
      default:
        break;
    }
  };

  return onClick;
};

export default useRedirect;

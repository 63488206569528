import {
  useNotificationStoresCollection,
  IRemoteNotification,
} from "@magicbell/magicbell-react";
import { Button, Modal, Typography } from "ritual-ui";
import { useGetNotificationContentQuery } from "src/api/instances/cms";
import Markdown from "react-markdown";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Routes } from "src/router/routes";
import { formatDate } from "../../utils/helpers";
import { TickCircle } from "src/assets/icons";
import { ScrollMark } from "src/components";
import { useRef } from "react";
import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";
import RitualAnalytics from "client-analytics";

interface ContentModalProps {
  notification: IRemoteNotification | null;
  onClose: () => void;
}

const ContentModal = ({ notification, onClose }: ContentModalProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const { markNotificationAsRead } = useNotificationStoresCollection();

  const actionUrl = notification?.actionUrl;
  const contentId = actionUrl?.substring(actionUrl.lastIndexOf("/") + 1);
  const { data, isFetching } = useGetNotificationContentQuery(contentId || "", {
    skip: !actionUrl,
  });

  const { data: expert } = useGetExpertQueryResolver();

  const confirmNotification = () => {
    if (notification) {
      markNotificationAsRead(notification);

      RitualAnalytics.track("expert_broadcast_confirmed", {
        broadcast_id: data?.broadcast_id,
        expert_uuid: expert?.uuid,
        expert_name: `${expert?.first_name} ${expert?.last_name}`,
      });

      window.analytics.track("expert_broadcast_confirmed", {
        broadcast_id: data?.broadcast_id,
        expert_uuid: expert?.uuid,
        expert_name: `${expert?.first_name} ${expert?.last_name}`,
      });
    }

    onClose();
  };

  return (
    <Modal isOpen={!!notification} onRequestClose={onClose}>
      <div className={styles.wrapper}>
        {data && <ScrollMark containerRef={ref} />}
        <Typography variant="title" weight={600}>
          {notification?.title}
        </Typography>
        <div className={styles.caption_wrapper}>
          <Typography variant="body-small">
            {formatDate(notification?.sentAt)}
          </Typography>
          {notification?.readAt && (
            <div className={styles.confirmed_text}>
              <TickCircle />
              <Typography variant="body-small">
                Confirmed on {formatDate(notification?.readAt)}
              </Typography>
            </div>
          )}
        </div>
        <div className={styles.content} ref={ref}>
          {actionUrl && !isFetching && <Markdown>{data?.content}</Markdown>}
          <div className={styles.action_row}>
            <Button
              id="notification_question_click"
              variant="text"
              className={styles.secondary_action}
              onClick={() =>
                navigate(Routes.SUPPORT, { state: { opened: true } })
              }
            >
              I have a question
            </Button>
            {!notification?.readAt && !isFetching && (
              <Button
                id="confirm_notification"
                variant="large"
                onClick={confirmNotification}
              >
                Mark as confirmed
              </Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContentModal;

import {
  IRemoteNotification,
  MagicBellProvider,
} from "@magicbell/magicbell-react";
import { useGetExpertQueryResolver } from "src/api/resolvers/expertResolvers";
import Notifications from "./components/NotificationsFeed";
import { useState } from "react";
import ContentModal from "./components/ContentModal";
import Header from "./components/Header";
import styles from "./styles.module.scss";

export const UpdateFeed = () => {
  const [notification, setNotification] = useState<IRemoteNotification | null>(
    null
  );

  return (
    <div className={styles.wrapper}>
      <Header />
      <Notifications onNotificationClick={setNotification} />
      <ContentModal
        notification={notification}
        onClose={() => setNotification(null)}
      />
    </div>
  );
};

export const UpdateFeedWithProvider = () => {
  const { data: expert } = useGetExpertQueryResolver();

  if (!expert) return <></>;

  return (
    <MagicBellProvider
      apiKey={process.env.MAGICBELL_API_KEY || ""}
      userEmail={expert.email}
      userExternalId={expert.uuid}
    >
      <UpdateFeed />
    </MagicBellProvider>
  );
};

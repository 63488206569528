import { ScrollMark as ScrollMarkIcon } from "src/assets/icons";
import { useScrollMark } from "./utils/useScrollMark";
import styles from "./styles.module.scss";
import { RefObject } from "react";

const ScrollMark = ({
  containerRef,
}: {
  containerRef: RefObject<HTMLDivElement>;
}) => {
  const show = useScrollMark(containerRef);

  if (!show) {
    return <></>;
  }

  return (
    <div className={styles.wrapper}>
      <ScrollMarkIcon />
    </div>
  );
};

export default ScrollMark;

import { createAction, createReducer } from "@reduxjs/toolkit";
import { useAppSelector } from "./hooks";

export const storeAuthToken = createAction<string>("token/store-token");

export interface State {
  token?: string;
}

const initialState: State = {
  token: "",
};

const tokenReducer = createReducer(initialState, (builder) => {
  builder.addCase(storeAuthToken, (state, action) => {
    state.token = action.payload;
  });
});

export function useGetTokenState() {
  return useAppSelector((state) => state.token);
}

export default tokenReducer;

import { PropsWithChildren } from "react";
import Header from "../Header";
import styles from "./styles.module.scss";
import Sidebar from "../Sidebar";
import { useLocation } from "react-router-dom";
import { stylesByPage } from "./utils/styleByPage";

const PageLayout = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.page} style={stylesByPage[pathname]}>
      <Header />
      <div className={styles.content}>
        <Sidebar />
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  );
};

export default PageLayout;

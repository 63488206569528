import { useGetConversationMessagesResolver } from "src/api/resolvers/supportResolvers";
import { useConversations } from "src/pages/Support/context/useConversations";
import { Chat, Loader } from "./ui";

const SupportChat = () => {
  const { activeConversation } = useConversations();
  const { data: messages, isFetching } = useGetConversationMessagesResolver(
    activeConversation?.id || ""
  );

  if (!activeConversation) return <></>;

  if (isFetching) return <Loader />;

  return <Chat messages={messages || []} conversation={activeConversation} />;
};

export default SupportChat;

import { MessagesArchived, MessagesOpen } from "src/assets/icons";
import { ConversationStatus } from "src/pages/Support/types";
import OpenConversationsContent from "../OpenConversationsContent";

export const content = {
  [ConversationStatus.OPEN]: {
    Icon: MessagesOpen,
    title: "No messages in Open threads",
    subtitle:
      "Something on your mind? We're here to help. Write us a message, and we'll promptly assist you with your concern.",
    children: <OpenConversationsContent />,
  },
  [ConversationStatus.ARCHIVED]: {
    Icon: MessagesArchived,
    title: "No messages in Archived threads",
    subtitle:
      "Nothing has been archived yet. If you have something on your mind, write us a message and we'll promptly assist you with your concern.",
  },
};

import { CheckMark } from "src/assets/icons";
import Button from "../Button";
import Typography from "../Typography";
import styles from "./styles.module.scss";

interface ModalAlertMessage {
  title: string;
  subtitle: string;
  button?: string;
  onClick: () => void;
}

const ModalAlertMessage = ({
  title,
  subtitle,
  button = "OK",
  onClick,
}: ModalAlertMessage) => {
  return (
    <div className={styles.wrapper}>
      <CheckMark />
      <Typography variant="body-large">{title}</Typography>
      <Typography variant="body2">{subtitle}</Typography>
      <Button onClick={onClick}>{button}</Button>
    </div>
  );
};

export default ModalAlertMessage;

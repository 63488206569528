import { useAuth0 } from "@auth0/auth0-react";
import { useGetExpertQuery } from "../instances/management";
import { useEffect, useMemo } from "react";
import { useGetTokenState } from "src/store/tokenReducer";

export function useGetExpertQueryResolver() {
  const { logout, user } = useAuth0();
  const { token } = useGetTokenState();
  const response = useGetExpertQuery(user?.email as string, {
    skip: !user?.email || !token,
  });

  useEffect(() => {
    if (response.data === null) logout();
  }, [response.data, logout]);

  return useMemo(() => {
    const isError = response.isError || response.data === null;
    return { ...response, isError };
  }, [response]);
}

export const useExpertData = () => {
  const { data } = useGetExpertQueryResolver();
  const expertId = data?.uuid || "";

  return { expertId, skip: !data };
};

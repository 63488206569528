import Skeleton from "react-loading-skeleton";
import styles from "./styles.module.scss";

const Loader = () => {
  return (
    <>
      {new Array(3).fill(0).map((_, i) => (
        <Skeleton key={i} className={styles.loader} />
      ))}
    </>
  );
};

export default Loader;

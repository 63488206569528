import { InfoCard } from "ritual-ui";
import { IRemoteNotification } from "@magicbell/magicbell-react";
import { formatDate } from "../../utils/helpers";
import styles from "./styles.module.scss";

interface NotificationProps {
  notifications: IRemoteNotification[];
  onNotificationClick: (notification: IRemoteNotification) => void;
}

const Notification = ({
  notifications,
  onNotificationClick,
}: NotificationProps) => {
  return (
    <div className={styles.wrapper}>
      {notifications.map((notification) => (
        <InfoCard
          key={notification.id}
          caption={
            notification.readAt
              ? `Confirmed on ${formatDate(notification.readAt)}`
              : formatDate(notification.sentAt)
          }
          title={notification.title}
          body={notification.content || ""}
          action={{
            id: "open_notification_content",
            onClick: () => {
              onNotificationClick(notification);
            },
            text: notification.readAt ? "Open" : "Open to confirm",
          }}
        />
      ))}
    </div>
  );
};

export default Notification;

import { FieldErrors, FieldValues } from "react-hook-form";

export const extractError = (errors: FieldErrors<FieldValues>) =>
  Object.values(errors)
    .find((error) => !!error?.message)
    ?.message?.toString();

export const generatePayload = (data: FieldValues) => {
  const { text, link, subject, attachments } = data;
  const payload = new FormData();
  let body = text;
  if (link) {
    body = body + `\nAttached link: ${link}`;
  }

  payload.append("body", body);
  payload.append("subject", subject.value);
  [...attachments].forEach((file, i) =>
    payload.append(`attachments[${i}]`, file)
  );

  return payload;
};

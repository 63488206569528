import { HTMLAttributes, ReactNode } from "react";
import styles from "./styles.module.scss";

const tagMapping: { [key: string]: JSX.ElementType } = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  body: "p",
  body2: "p",
  body3: "p",
  ["body-large"]: "p",
  ["body-sm"]: "p",
};

interface TypographyProps extends HTMLAttributes<HTMLElement> {
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "body"
    | "body2"
    | "body3"
    | "body-large"
    | "body-sm";
  children: ReactNode;
}

const Typography = ({
  variant = "body",
  children,
  ...rest
}: TypographyProps) => {
  const Tag = tagMapping[variant];
  return (
    <Tag
      {...rest}
      className={[
        styles[`typography-${variant}`],
        styles.typography_default,
        rest.className || "",
      ].join(" ")}
    >
      {children}
    </Tag>
  );
};

export default Typography;

import { Dispatch, SetStateAction } from "react";
import { ChangeEvent } from "react";
import { Attachments } from "src/assets/icons";

const FilePicker = ({
  setFiles,
}: {
  setFiles: Dispatch<SetStateAction<File[]>>;
}) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFiles([...event.target.files]);
    }
  };

  return (
    <div>
      <label htmlFor="file-upload">
        <Attachments />
      </label>
      <input
        id="file-upload"
        type="file"
        multiple
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default FilePicker;

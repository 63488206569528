import {
  IRemoteNotification,
  useNotifications,
} from "@magicbell/magicbell-react";
import { useState } from "react";
import styles from "./style.module.scss";
import cn from "classnames";
import Notification from "../Notification";
import { NotificationStatus } from "../../utils/types";
import { EmptyState } from "ritual-ui";
import { emptyStateContent } from "../../utils/content";
import Skeleton from "react-loading-skeleton";

const NotificationsFeed = ({
  onNotificationClick,
}: {
  onNotificationClick: (notification: IRemoteNotification) => void;
}) => {
  const store = useNotifications();
  const [notificationFilter, setNotificationFilter] = useState(
    NotificationStatus.ACTIVE
  );

  const notifications = store?.notifications.filter(
    ({ readAt }) => !!notificationFilter === !readAt
  );

  const getFilterProps = (status: NotificationStatus) => ({
    onClick: () => setNotificationFilter(status),
    className: cn({
      [styles.filter_selected]: notificationFilter === status,
    }),
  });

  if (!store?.lastFetchedAt) {
    return (
      <div className={styles.loader}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <p {...getFilterProps(NotificationStatus.ACTIVE)}>Active</p>
        <p {...getFilterProps(NotificationStatus.ARCHIVE)}>Archive</p>
      </div>

      {notifications?.length ? (
        <Notification
          notifications={notifications}
          onNotificationClick={onNotificationClick}
        />
      ) : (
        <div className={styles.wrapper}>
          <EmptyState {...emptyStateContent[notificationFilter]} />
        </div>
      )}
    </div>
  );
};

export default NotificationsFeed;

import { configureStore } from "@reduxjs/toolkit";
import tokenReducer from "./tokenReducer";
import { cmsApi, managementApi } from "src/api/instances";

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    [managementApi.reducerPath]: managementApi.reducer,
    [cmsApi.reducerPath]: cmsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(cmsApi.middleware)
      .concat(managementApi.middleware),
});

import EmptyState from "src/components/layout/EmptyState";
import { useConversations } from "../../context/useConversations";
import { content } from "./utils/content";

const SupportEmptyState = () => {
  const { status } = useConversations();

  return (
    <div style={{ marginTop: "80px" }}>
      <EmptyState {...content[status]} />
    </div>
  );
};

export default SupportEmptyState;

import { MutableRefObject, useEffect } from "react";

const useScrollToBottom = (
  ref: MutableRefObject<HTMLDivElement | null>,
  messages: unknown
) => {
  useEffect(() => {
    const elem = ref.current;
    if (!elem) return;
    elem.scrollTop = elem.scrollHeight;
  }, [messages, ref]);
};

export default useScrollToBottom;
